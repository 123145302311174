import { Box, Typography, useMediaQuery } from "@mui/material";

import title from '../assets/localshirtTitle.svg';
import extension from '../assets/extension.svg';
import theme from "../theme";

export default function PresentationExtension() {

  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        marginX: "30px",
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: 'space-between'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <img
            src={title}
            alt="Title Image"
            style={{
              width: '40vw',
              minWidth: '300px'
            }}
          />

          <Typography
            variant="h2"
            sx={{
              paddingTop: '3vw',
              textAlign: 'left',
              maxWidth: '700px',
            }}
          >
            Une mode
            <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}>
              {' '}
              éthique
            </Box>{' '}
            à ton image : <br />
            Trouvons le vêtement
            <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}>
              {' '}
              éco-responsable{' '}
            </Box>
            qui reflète ton style
            <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}>
              {' '}
              unique
            </Box>{' '}
            !
          </Typography>

          <Box
            onClick={() => window.open('https://drive.google.com/file/d/1F4CeQ77NJUwLB3a9XJrv03Z3H8ob92N_/view?usp=sharing', '_blank')}
            sx={{
              bgcolor: "green.main",
              borderRadius: "20px",
              padding: "20px",
              marginTop: "30px",
              maxWidth: "300px",
              ":hover": { cursor: "pointer" },
            }}
          >
            <Typography
              variant="h2"
              color="white.light"
              align="center"
            >
              Télécharger l'extension
            </Typography>
          </Box>
        </Box>
        <img src={extension} alt="New Image" style={{
          height: 'auto',
          width: isMobile ? '40vw': '65vw',
          margin: "40px",
          alignSelf: "center"
        }}/>
      </Box>
    </Box>
  );
}