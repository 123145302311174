import React from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import Register from './components/register/Register';
import SiteMap from './components/sitemap/SiteMap';
import Catalog from './pages/catalog/Catalog';
import CGV from './components/legalMentions/CGV';
import Confidentiality from './components/legalMentions/Confidentiality';
import CGU from './components/legalMentions/CGU';
import LegalMentions from './components/legalMentions/LegalMentions';
import AddArticle from './components/addArticlePage/AddArticle';
import MyAccount from './components/myAccount/MyAccount';
import { ToastContainer } from 'react-toastify';
import DevConfirmation from './devConfirmation/devConfirmation';
import RegistrationConfirmation from './RegistrationConfirmation/RegistrationConfirmation';
import InformationPage from './informationPage/Information';
import ModifyLocalScoreData from './ModifyLocalScoreData';
import { createRoot } from 'react-dom/client';
import ChangePassword from './components/password/ChangePassword';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import theme from './theme';
import ForgotPassword from './components/password/ForgotPassword';
import './index.css';
import Forbidden from './components/forbidden/Forbidden';
import Footer from './Footer';
import RegisterConfirmation from './components/register/RegisterConfirmation';
import Result from './pages/linkAnalyze/result';
import AdminPage from './pages/admin/Admin';
import UpdateArticle from './components/ModifyArticlePage/ModifyArticle';
import AuthGuard from './components/authGuard/authGuard';
import SearchAlternative from './pages/linkAnalyze/searchAlternative';

const AppWithRouter = () => {
  return (
    <Router>
      <Footer />
      <Routes>
        <Route path="/" element={ <InformationPage />} />
        <Route path="/information" element={<InformationPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password/:emails" element={<ChangePassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/forgotPasswordEmail" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/searchAlternative" element={<SearchAlternative />} />
        <Route path="/siteMap" element={<SiteMap />} />
        <Route path="/cgv" element={<CGV />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/confirm" element={<RegistrationConfirmation />} />
        <Route path="/legalMentions" element={<LegalMentions />} />
        <Route path="/confirm_registration" element={<RegisterConfirmation />} />
        <Route element={<AuthGuard />}>
          <Route path="/mon-compte" element={<MyAccount />} />
          <Route path="/addArticle" element={<AddArticle />} />
          <Route path="/updateArticle/:articleId" element={<UpdateArticle />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/admin" element={<AdminPage />} />
        </Route>
        <Route path="/confidentiality" element={<Confidentiality />} />
        <Route path="/modify_database" element={<ModifyLocalScoreData />} />
        <Route path="/confirm_dev" element={<DevConfirmation />} />
        <Route path="/result" element={<Result />} />
        <Route path="/*" element={<Forbidden />} />
        <Route path="/404" element={<Forbidden />} />
      </Routes>
    </Router>
  );
};

const container = document.getElementById('root');
if (container !== null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <AppWithRouter />
        <ToastContainer />
      </ThemeProvider>
    </React.StrictMode>,
  );
} else {
  console.error('Element with id "root" not found.');
}

reportWebVitals();
