import React, { useState } from 'react';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import { GreyButton } from '../../components/buttons/buttons';
import { useParams } from 'react-router-dom';
import { validatePassword } from './../tools/validatePassword';
import { showToast } from '../tools/toast';
import Navbar from '../navbar/Navbar';
import { useCookies } from 'react-cookie';

const ChangePassword = () => {
  const { emails } = useParams();
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const logOut = () => {
    removeCookie('token');
}

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (validatePassword(password, confPassword) === false) {
        return;
      }

      const { data } = await client.query({
        query: gql`
          query ResetPassword($emails: String!, $password: String!, $confPassword: String!) {
            forget_password(
              resetPasswordReference: $emails
              password: $password
              confirm_password: $confPassword
            ) {
              status
              devMessage
            }
          }
        `,
        variables: { emails, password, confPassword },
      });
      if (data.forget_password.status === 200) {
        setSubmitted(true);
        logOut();
      } else if (data.forget_password.status === 401) {
        showToast('error', data.forget_password.content.msg);
      } else {
        throw new Error("bad ajax request");
      }
    } catch (error) {
      console.error('Error: AJAX request', error);
    }
  };

  return (
    <Box sx={{ overflow: 'hidden', height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0 }}>
      <Navbar />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
        <Typography sx={{ padding: '20px', fontWeight: 'bold' }} variant={'h1'} color={'green.main'}>
          Réinitialisation du mot de passe
        </Typography>
        <Paper elevation={10} sx={{ backgroundColor: 'white', width: { xs: '90%', sm: '600px' }, height: '360px', padding: '20px', boxSizing: 'border-box', borderRadius: '20px' }}>
          {submitted ? (
            <Typography sx={{ padding: '30px' }} variant={'h3'}>
              Le mot de passe a été réinitialisé avec succès! <br /><br />
              Heureux de vous revoir!
            </Typography>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginLeft: '20px' }}>
                <Typography sx={{ marginBottom: '30px' }} variant={'h3'}>
                  Veuillez entrer votre nouveau mot de passe
                </Typography>
                <TextField
                  label="Nouveau mot de passe"
                  variant="outlined"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ width: { xs: '90%', sm: '80%' }, marginBottom: '20px' }}
                />
                <TextField
                  label="Confirmer le mot de passe"
                  variant="outlined"
                  value={confPassword}
                  type="password"
                  onChange={(e) => setConfPassword(e.target.value)}
                  sx={{ width: { xs: '90%', sm: '80%' }, marginBottom: '40px' }}
                />
                <Button variant="contained" size="small" sx={{ ...GreyButton(), width: '150px', height: '50px' }} onClick={handleSubmit}>
                  <Typography color={'white.light'}>continuer</Typography>
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ChangePassword;
