import { gql, useQuery } from "@apollo/client";
import client from "../graphqlApollo";
import React, { useEffect, useState } from "react";
import {Box, Link, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import searchLogo from "../assets/search_logo.svg";
import notFound from '../assets/bubble-gum-error-404.gif'
import { showToast } from "../components/tools/toast";
import theme from "../theme";

export default function PartnerList() {
    const { loading, error, data } = useQuery(gql`
        query {
          get_companies {
            status
            devMessage
            userMessage
            companies {
              name
              logo
              description
              businessLink
            }
          }
        }`, { client });
    const [companies, setCompanies] = useState<{
        name: string,
        logo: string,
        description: string,
        businessLink: string
    }[]>([]);
    const [searchBarContent, setSearchBarContent] = useState<string>('');
    const [isLoading, setLoading] = useState(true);

    const isMobile = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (!loading && !error && data) {
            if (data.get_companies.status != 200) {
                showToast('error', data.get_companies.userMessage);
                setLoading(false);
            } else {
                setCompanies(data.get_companies.companies);
                setLoading(false);
            }
        }
    }, [loading, error, data]);

    return (
        <Stack>
            <Stack
                alignItems="center"
                bgcolor={'green.dark'}
                borderRadius={'20px'}
                width={'80vw'}
                padding={"40px"}
            >
                <Typography fontWeight={'bold'} color={'white.main'} variant="h1" margin="40px">
                    Découvrez nos partenaires !
                </Typography>
                <Stack bgcolor={'white.main'} direction={'row'} width={'90%'} borderRadius={'10px'} margin={'20px'}>
                    <img src={searchLogo} width={'1vw'} style={{ paddingLeft: '10px' }} />
                    <TextField
                        sx={{
                            width: '100%',
                            fieldset: {
                                border: 'none',
                            },
                            input: {
                                color: theme.palette.gray.dark,
                            },
                        }}
                        placeholder={'Rechercher un partenaire...'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchBarContent(e.target.value)}
                    />
                </Stack>
                <Stack rowGap={'5px'} width={'90%'} sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
                    {companies.filter(company => company.name.toLowerCase().includes(searchBarContent.toLowerCase())).length > 0 ?
                        companies.filter(company => company.name.toLowerCase().includes(searchBarContent.toLowerCase())).map(company => (
                            <Box key={company.name} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                bgcolor: 'white.main',
                                borderRadius: '20px',
                                boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 10%)",
                                overflow: 'hidden',
                            }}>
                                <Box borderRadius="5px" bgcolor="white" style={{
                                    width: '100px', height: '100px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <img
                                        src={company.logo}
                                        alt="Company Logo"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>

                                <Stack sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    paddingLeft: '5%',
                                }}>
                                    <Typography variant="h2">{company.name}</Typography>
                                    <Typography variant="h3">{company.description}</Typography>
                                </Stack>

                                <Link target="_blank" href={company.businessLink} rel="noreferrer" style={{ alignSelf: 'center' }}>
                                    <Box bgcolor={'green.dark'} borderRadius={'10px'} marginRight={'10px'} width={isMobile ? '200px' : '150px'} height={'50px'}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        <Typography variant="h3" color={'white.light'} align="center">En savoir plus</Typography>
                                    </Box>
                                </Link>
                            </Box>
                        )) :
                        (<Stack alignItems={'center'} justifyContent={'center'} maxWidth={'100%'} maxHeight={'100%'}>
                            <img src={notFound} style={{ maxWidth: '80%', maxHeight: '80%' }} />
                            <Typography fontSize={25} color={'white.main'}>Pas de partenaire correspondant</Typography>
                        </Stack>)
                    }
                </Stack>
            </Stack>
        </Stack>
    );
}
