import Navbar from '../../components/navbar/Navbar';
import {Box} from '@mui/material';
import LocalImageAnalyze from './LocalImageAnalyze';

const ForgotPassword = () => {

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex', // Enables flexbox
        flexDirection: 'column', // Ensures vertical layout
      }}
    >
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1, // Ensures it takes up available space
          overflow: 'auto'
        }}
      >
        <LocalImageAnalyze />
      </Box>
    </Box>

  );
};

export default ForgotPassword;
