import { Box } from "@mui/material";

import localscoreProcess from '../assets/calcul.svg';

export default function ScorePresentation() {
  return (
    <Box
      sx={{
      }}
    >
      <img src={localscoreProcess} alt="localscoreProcess" style={{
        width: '70vw',
      }} />
    </Box>
  );
}