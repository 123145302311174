import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    Slider,
    Stack,
    styled,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { toast } from 'react-toastify';
import { gql, useQuery } from "@apollo/client";
import client from "../../graphqlApollo";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import add_image from '../../assets/add_image.svg';
import plus_image from '../../assets/plus_image.svg';
import minus_image from '../../assets/minus_image.svg';
import { Center } from "../../informationPage/Information";
import Loading from '../loading/Loading';
import Forbidden from "../forbidden/Forbidden";
import { showToast } from "../tools/toast";
import Navbar from '../navbar/Navbar';

type InputQuestion = {
    questionTag: string,
    criteria_application: string,
    user_response_type: string,
    factor: number,
    minimize: boolean
}

type AdditionalCriteria = {
    result: string,
    question: InputQuestion
}

type CriteriaInputType = {
    name: string,
    id: number
}

type TransportInputType = {
    name: string,
    percent: number,
    id: number
}

type ArticleQueryContent = {
    name: string,
    brand: string,
    redirection_url: string,
    material: CriteriaInputType[],
    country: CriteriaInputType[],
    transport: TransportInputType[],
    price: string,
    image: string,
    description: string,
    environnementdesc: string,
    ethicaldesc: string,
    additionalCriteria: AdditionalCriteria[],
    type: string
}

type QuestionContent = {
    id: string,
    tag: string,
    content: string,
    criteria_target: string,
    criteria_application: string,
    user_response_type: string,
    factor: number,
    minimize: boolean
}

export const TextInputField = styled(TextField)(({ theme }) => ({
    width: '100%',
    input: {
        color: theme.palette.gray.dark, height: '0.5vh', border: '1px solid gray.light',
    },
}));

export const TwoElementStack = styled(Stack)(({ theme }) => ({
    rowGap: '1vh'
}));

const ToggleGroupObject = ({
    onChange = (value: boolean) => {
        return;
    }
}) => {
    const [toggleValue, setToggleValue] = useState<0 | 1 | 2>(0);

    return (<ToggleButtonGroup value={toggleValue} onChange={(event, newValue) => {
        if (newValue !== null) {
            setToggleValue(newValue);
            onChange(newValue == 1);
        }
    }} exclusive>
        <ToggleButton value={1}><Typography>Oui</Typography></ToggleButton>
        <ToggleButton value={2}><Typography>Non</Typography></ToggleButton>
    </ToggleButtonGroup>);
}

export default function AddArticle() {
    const [isLoading, setLoading] = useState(true);
    const [cookies] = useCookies(['token']);
    const [openDialog, setOpenDialog] = useState(false);


    const { loading, error, data } = useQuery(gql`
        query {
            getAllQuestions
            {
                id
                tag
                content
                criteria_target
                criteria_application
                user_response_type
                factor
                minimize
            }
        }
    `, {
        client, context: {
            headers: {
                authorization: "Bearer " + cookies.token,
            },
        }
    });

    const [questions, setQuestions] = useState<QuestionContent[]>([])
    const [articleData, setArticleData] = useState<ArticleQueryContent>({
        name: '',
        brand: '',
        redirection_url: '',
        material: [],
        country: [],
        transport: [],
        price: '',
        image: '',
        description: '',
        environnementdesc: '',
        ethicaldesc: '',
        additionalCriteria: [],
        type: ''
    });

    useEffect(() => {
        if (!loading && !error && data) {
            setLoading(false);
            const temporaryQuestions: QuestionContent[] = []
            data.getAllQuestions.forEach((question: QuestionContent) => {
                if (!(question.criteria_target === 'article_partner')) return
                temporaryQuestions.push(question);
            })
            setQuestions(temporaryQuestions);
        }
    }, [loading, error, data]);

    const AddImageButton = () => {
        return (
            <Center component={'label'} height={'100%'} width={'100%'} bgcolor={'white.light'} border={'solid 1px'}
                borderRadius={'20px'} borderColor={'gray.light'}>
                {articleData.image == ''
                    ? <img src={add_image} style={{ width: '10vw', height: '10vh' }} />
                    : <img src={articleData.image} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                }
                <input type={'file'} accept={'image/gif, image/jpeg, image/png, image/webp'} onChange={(value: any) => {
                    if (!value || !value.target || !value.target.files)
                        return;
                    const reader = new FileReader();
                    reader.readAsDataURL(value.target.files[0]);
                    reader.onloadend = () => {
                        const base64Image = reader.result?.toString();
                        if (base64Image)
                            setArticleData({ ...articleData, image: base64Image });
                    }
                }} style={{ display: 'none' }} />
            </Center>
        )
    }

    const getAppropriateObject = (question: QuestionContent) => {
        switch (question.user_response_type) {
            case 'text':
            case 'number':
                return <TextInputField onChange={(value: any) => {
                    try {
                        articleData.additionalCriteria.push({
                            result: value.currentTarget.value, question: {
                                questionTag: question.id,
                                criteria_application: question.criteria_application,
                                user_response_type: question.user_response_type,
                                factor: question.factor,
                                minimize: question.minimize
                            }
                        })
                    } catch (err) {
                        showToast('error', (err as Error).message);
                    }
                }} />
            case 'range':
                return <Slider onChange={(value: any) => {
                    try {
                        articleData.additionalCriteria.push({
                            result: value.toString(), question: {
                                questionTag: question.id,
                                criteria_application: question.criteria_application,
                                user_response_type: question.user_response_type,
                                factor: question.factor,
                                minimize: question.minimize
                            }
                        });
                    } catch (err) {
                        showToast('error', (err as Error).message);
                    }
                }} />
            case 'boolean':
                return <ToggleGroupObject onChange={(value: boolean) => {
                    try {
                        const existingCriteriaIndex = articleData.additionalCriteria.findIndex(
                            (criteria) => criteria.question.questionTag === question.id
                        );

                        const newCriteria = {
                            result: value ? 'true' : 'false',
                            question: {
                                questionTag: question.id,
                                criteria_application: question.criteria_application,
                                user_response_type: question.user_response_type,
                                factor: question.factor,
                                minimize: question.minimize
                            }
                        };

                        if (existingCriteriaIndex !== -1) {
                            articleData.additionalCriteria[existingCriteriaIndex] = newCriteria;
                        } else {
                            articleData.additionalCriteria.push(newCriteria);
                        }
                    } catch (err) {
                        showToast('error', (err as Error).message);
                    }
                }} />;
                return <></>
        }
    }

    const sendToDataBase = () => {
        const missingFields = [];

        if (!articleData.name.trim()) missingFields.push('nom');
        if (!Array.isArray(articleData.material) || articleData.material.length < 1) missingFields.push('matériaux');
        if (!Array.isArray(articleData.country) || articleData.country.length < 1) missingFields.push('pays');
        if (!Array.isArray(articleData.transport) || articleData.transport.length < 1) missingFields.push('transport');
        if (!articleData.price || isNaN(Number(articleData.price)) || Number(articleData.price) <= 0) missingFields.push('prix valide');
        if (!articleData.image || !articleData.image.startsWith('data:image/')) missingFields.push('image valide');
        if (!articleData.description.trim()) missingFields.push('description');
        if (!articleData.environnementdesc.trim()) missingFields.push('description environnementale');
        if (!articleData.ethicaldesc.trim()) missingFields.push('description éthique');
        const urlRegex = new RegExp(
            '^(https?:\\/\\/)' + // protocole obligatoire (http ou https)
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // nom de domaine
            '(\\d{1,3}\\.){3}\\d{1,3})' + // ou adresse IPv4
            '(\\:\\d+)?' + // port optionnel
            '(\\/[-a-zA-Z\\d%_.~+&:]*)*' + // chemin optionnel
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // chaîne de requête optionnelle
            '(\\#[-a-zA-Z\\d_]*)?$' // fragment optionnel
        );

        if (!articleData.redirection_url.trim() || !urlRegex.test(articleData.redirection_url)) {
            missingFields.push('URL de redirection valide');
        }

        articleData.material.forEach((material, index) => {
            if (!material.name || material.name.trim() === '') {
                missingFields.push(`nom du matériau ligne ${index + 1}`);
            }
        });

        articleData.country.forEach((country, index) => {
            if (!country.name || country.name.trim() === '') {
                missingFields.push(`nom du pays ligne ${index + 1}`);
            }
        });

        articleData.transport.forEach((transport, index) => {
            if (!transport.name || transport.name.trim() === '') {
                missingFields.push(`nom du transport ligne ${index + 1}`);
            }
            if (isNaN(Number(transport.percent)) || Number(transport.percent) <= 0) {
                missingFields.push(`distance positive en km pour le transport ligne ${index + 1}`);
            }
        });


        if (articleData.additionalCriteria.length !== questions.length) {
            missingFields.push('réponses à ' + (questions.length - articleData.additionalCriteria.length) + ' questions');
        }


        if (missingFields.length > 0) {
            showToast('error', `Veuillez remplir les champs suivants : ${missingFields.join(', ')}.`);
            return;
        }
        const queryText = `query {createArticlePartner (
            name: "${articleData.name}"
            material: [${articleData.material.map(material => `{name: "${material.name}"}`)}]
            country: [${articleData.country.map(country => `{name: "${country.name}"}`)}]
            transport: [${articleData.transport.map(transport => `{name: "${transport.name}", percent: ${transport.percent}}`)}]
            price: "${articleData.price}"
            image: "${articleData.image}"
            description: "${articleData.description}"
            environnement_desc: "${articleData.environnementdesc}"
            ethical_desc: "${articleData.ethicaldesc}"
            redirection_url:"${articleData.redirection_url}"
            additionalCriteria: [${articleData.additionalCriteria.map(article => {
            return `{result: "${article.result}", 
                question: {
                          questionTag: "${article.question.questionTag}"
                          criteria_application: "${article.question.criteria_application}"
                          user_response_type: "${article.question.user_response_type}"
                          factor: ${article.question.factor}
                          minimize: ${article.question.minimize}
                }}`
        })}]
          ) {
            status
            devMessage
            userMessage
          }}`;
        const qr = gql(queryText);
        client.query({
            query: qr,
            context: {
                headers: {
                    authorization: "Bearer " + cookies.token,
                },
            },
        }).then((req) => {
            if (req.data.createArticlePartner.status == 500) {
                toast.error('An error occurred while adding the article. Please try again with a different image.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                setOpenDialog(true);
            }
        }).catch(() => {
            toast.error('An error occurred in server. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const VariableRowTextInputField = (criteriaName: string) => {
        const [lines, setLines] = useState<{ id: number, element: JSX.Element }[]>([]);

        const addLine = () => {
            let newIndex = 0;
            for (; newIndex <= lines.length; newIndex++) {
                let isIn = false;
                for (let j = 0; j < lines.length; j++) {
                    if (lines[j].id === newIndex) {
                        isIn = true;
                    }
                }
                if (!isIn) {
                    break;
                }
            }
            switch (criteriaName) {
                case 'transport':
                    articleData.transport.push({ id: newIndex, name: '', percent: -1 });
                    setLines([...lines, {
                        id: newIndex, element: <Grid container size={{ xs: 10 }}>
                            <Grid size={{ xs: 5 }} paddingRight={'0.5vw'}>
                                <TextInputField placeholder={'Ex: avion'} onChange={(value: any) => {
                                    const transportData = articleData.transport.find(transport => transport.id === newIndex);
                                    if (transportData)
                                        transportData.name = value.target.value;
                                }}></TextInputField>
                            </Grid>
                            <Grid size={{ xs: 5 }} paddingRight={'0.25vw'} paddingLeft={'0.25vw'}>
                                <TextInputField placeholder={'Ex: 80km'} onChange={(value: any) => {
                                    const transportData = articleData.transport.find(transport => transport.id === newIndex);
                                    if (transportData)
                                        try {
                                            const numberRegex = new RegExp('^[0-9]*$');
                                            if (!numberRegex.exec(value.target.value))
                                                return;
                                            transportData.percent = Number(value.target.value);
                                        } catch (err) {
                                            console.error(err)
                                        }
                                }}></TextInputField>
                            </Grid>
                        </Grid>
                    }]);
                    break;
                case 'country':
                    articleData.country.push({ id: newIndex, name: '' });
                    setLines([...lines, {
                        id: newIndex, element: <Grid size={{ xs: 10 }}>
                            <TextInputField placeholder={'Ex: France'} onChange={(value: any) => {
                                const countryData = articleData.country.find(country => country.id === newIndex);
                                if (countryData)
                                    countryData.name = value.target.value;
                            }}></TextInputField>
                        </Grid>
                    }]);
                    break;
                case 'material':
                    articleData.material.push({ id: newIndex, name: '' });
                    setLines([...lines, {
                        id: newIndex, element: <Grid size={{ xs: 10 }}>
                            <TextInputField placeholder={'Ex: Coton'} onChange={(value: any) => {
                                const materialData = articleData.material.find(material => material.id === newIndex);
                                if (materialData)
                                    materialData.name = value.target.value;
                            }}></TextInputField>
                        </Grid>
                    }]);
                    break;
                default:
                    break;

            }
        }

        const removeLine = (lineId: number) => {
            switch (criteriaName) {
                case 'transport':
                    setArticleData({
                        ...articleData,
                        transport: articleData.transport.filter(transport => transport.id !== lineId)
                    })
                    break;
                case 'country':
                    setArticleData({
                        ...articleData,
                        country: articleData.country.filter(country => country.id !== lineId)
                    })
                    break;
                case 'material':
                    setArticleData({
                        ...articleData,
                        material: articleData.material.filter(material => material.id !== lineId)
                    })
                    break;
                default:
                    break;
            }
            setLines(lines.filter(line => line.id !== lineId));
        }

        if (lines.length < 1)
            addLine();

        return (
            <Stack rowGap={'1vh'}>
                {lines.map((line) => {
                    return (
                        <Grid container alignItems={'center'}>
                            {line.element}
                            <Grid size={{ xs: 1 }} alignItems={'center'} container paddingRight={'0.25vw'} paddingLeft={'0.25vw'}>
                                <img src={plus_image} style={{ cursor: 'pointer' }} onClick={() => addLine()} />
                            </Grid>
                            {lines.length > 1 && <Grid size={{ xs: 1 }} alignItems={'center'} container>
                                <img src={minus_image} style={{ cursor: 'pointer' }} onClick={() => removeLine(line.id)} />
                            </Grid>}
                        </Grid>
                    )
                })}
            </Stack>
        )
    }

    return (!cookies.token ? <Forbidden /> :
        <React.Fragment>
            <Box
                sx={{
                    position: 'relative',
                    overflowX: 'hidden',
                }}
            >
                <Navbar />
            </Box>
            <Box width={'auto'} justifyContent={'center'} alignItems={'center'} display={'flex'}
                sx={{ '&::-webkit-scrollbar': { display: 'none' } }} overflow={'hidden'}>
                <Box alignItems="center" bgcolor={'white.main'} borderRadius={'20px'} width={'70vw'}
                    sx={{ boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 8%)" }}
                    display={"flex"} marginY={'15vh'} paddingY={'3vh'}>
                    <Loading open={isLoading} />
                    <Grid width={"95%"} height={"90%"} marginX={'auto'} spacing={'2vh'} container
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Box>
                            <Typography variant={'h1'}>Ajouter un article</Typography>
                        </Box>
                        <Grid container
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            sx={{ textAlign: { xs: 'center', md: 'left' } }}
                            width={'100%'} rowSpacing={'4vh'}>
                            <Grid size={{ xs: 12, md: 6 }} paddingRight={'3vw'} container rowGap={'4vh'}>
                                <TwoElementStack width={'100%'}>
                                    <Typography variant={'h2'}>Mon produit</Typography>
                                    <Divider />
                                </TwoElementStack>
                                <Grid container width={'80%'} justifyContent={{ xs: 'center' }} sx={{ width: { xs: "auto", md: "80%" }, marginLeft: { xs: "15px" } }}>
                                    <Grid container size={{ xs: 12, md: 6 }} paddingRight={'2vw'}>
                                        <AddImageButton />
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 6 }} rowGap={'2vh'} container >
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Nom du produit <span style={{ color: 'red' }}>*</span></Typography>
                                            <TextInputField placeholder={'Ex: robe longue fushia'}
                                                onChange={(value: any) => {
                                                    setArticleData({ ...articleData, name: value.target.value })
                                                }}></TextInputField>
                                        </TwoElementStack>
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Prix (en euros)<span style={{ color: 'red' }}>*</span></Typography>
                                            <TextInputField placeholder={'Ex: 10'} onChange={(value: any) => {
                                                try {
                                                    const numberRegex = new RegExp('^[0-9]*$');
                                                    if (!numberRegex.exec(value.target.value))
                                                        return;
                                                    setArticleData({ ...articleData, price: value.target.value })
                                                } catch (err) {
                                                    showToast('error', (err as Error).message);
                                                }
                                            }}></TextInputField>
                                        </TwoElementStack>
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Description<span style={{ color: 'red' }}>*</span></Typography>
                                            <TextInputField placeholder={'Cette robe fushia...'} onChange={(value: any) => {
                                                setArticleData({ ...articleData, description: value.target.value })
                                            }}></TextInputField>
                                        </TwoElementStack>
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Url de l'article<span style={{ color: 'red' }}>*</span></Typography>
                                            <TextInputField placeholder={'http://...'}
                                                onChange={(value: any) => {
                                                    setArticleData({
                                                        ...articleData,
                                                        redirection_url: value.target.value
                                                    })
                                                }}></TextInputField>
                                        </TwoElementStack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }} paddingLeft={'3vw'} container alignContent={'start'} rowGap={'4vh'}>
                                <TwoElementStack width={'100%'}>
                                    <Typography variant={'h2'}>Description</Typography>
                                    <Divider />
                                </TwoElementStack>
                                <Grid rowGap={'2vh'} container width={'100%'}>
                                    <TwoElementStack width={'100%'}>
                                        <Typography variant={'h3'}>Quels sont les efforts éthiques apportés à cet
                                            article?<span style={{ color: 'red' }}>*</span></Typography>
                                        <TextInputField multiline rows={2} onChange={(value: any) => {
                                            setArticleData({ ...articleData, environnementdesc: value.target.value })
                                        }}
                                            placeholder={'Ex: Cet article est produit 100% en france dans la ville du Mans'}></TextInputField>
                                    </TwoElementStack>
                                    <TwoElementStack width={'100%'}>
                                        <Typography variant={'h3'}>Quels sont les efforts écologiques apportés à cet
                                            article?<span style={{ color: 'red' }}>*</span></Typography>
                                        <TextInputField multiline rows={2} onChange={(value: any) => {
                                            setArticleData({ ...articleData, ethicaldesc: value.target.value })
                                        }}
                                            placeholder={'Ex: Cet article est composé uniquement de coton bio en provenance d’europe'}></TextInputField>
                                    </TwoElementStack>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 12 }}
                                rowGap={'4vh'}
                                container
                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                sx={{ textAlign: { xs: 'center', md: 'left' } }}
                            >
                                <TwoElementStack width={'100%'}>
                                    <Typography variant={'h2'}>Composition et provenance</Typography>
                                    <Divider />
                                </TwoElementStack>
                                <Grid container direction={"row"} width={'100%'}>
                                    <Grid size={{ xs: 12, md: 4 }} paddingRight={'3vw'}>
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Matière<span style={{ color: 'red' }}>*</span></Typography>
                                            {VariableRowTextInputField('material')}
                                        </TwoElementStack>
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 4 }} paddingRight={'1.5vw'} paddingLeft={'1.5vw'}>
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Origine<span style={{ color: 'red' }}>*</span></Typography>
                                            {VariableRowTextInputField('country')}
                                        </TwoElementStack>
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 4 }} container paddingLeft={'3vw'}>
                                        <TwoElementStack>
                                            <Typography variant={'h3'}>Moyen d'acheminement et distance<span style={{ color: 'red' }}>*</span></Typography>
                                            {VariableRowTextInputField('transport')}
                                        </TwoElementStack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {questions.filter(question => question.criteria_application === 'ecological').length > 0 &&
                                <Grid justifyContent={{ xs: 'center', md: 'flex-start' }}
                                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                                    size={{ xs: 12, md: 6 }} rowGap={'4vh'} container>
                                    <TwoElementStack>
                                        <Typography variant={'h2'}>Informations écologiques supplémentaires</Typography>
                                        <Divider />
                                    </TwoElementStack>
                                    <Stack spacing={2}>
                                        {questions.filter(question => question.criteria_application === 'ecological').map(question => {
                                            return (<TwoElementStack>
                                                <Typography variant={'h3'}>{question.content}<span style={{ color: 'red' }}>*</span></Typography>
                                                {getAppropriateObject(question)}
                                            </TwoElementStack>)
                                        })}
                                    </Stack>
                                </Grid>}
                            {questions.filter(question => question.criteria_application === 'ethical').length > 0 &&
                                <Grid container
                                    size={{ xs: 12, md: 6 }}
                                    rowGap={'4vh'}
                                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                                >
                                    <TwoElementStack>
                                        <Typography variant={'h2'}>Informations éthiques supplémentaires</Typography>
                                        <Divider />
                                    </TwoElementStack>
                                    <Stack spacing={2} height={'100%'} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                        {questions.filter(question => question.criteria_application === 'ethical').map(question => {
                                            return (<TwoElementStack >
                                                <Typography variant={'h3'}>{question.content}<span style={{ color: 'red' }}>*</span></Typography>
                                                {getAppropriateObject(question)}
                                            </TwoElementStack>)
                                        })}
                                    </Stack>
                                </Grid>}
                            <Grid container
                                size={{ xs: 12, md: 6 }}
                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                marginBottom={'3vh'}
                                sx={{ textAlign: { xs: 'center', md: 'left' } }}
                            >
                                <Center bgcolor={'green.main'} borderRadius={'5px'} width={'100%'} maxWidth={{ xs: '100%', md: '10vw' }}
                                    height={'5vh'} paddingX={'1vw'}
                                    onClick={() => sendToDataBase()} sx={{ ":hover": { cursor: "pointer" } }}>
                                    <Typography variant={'button'} color={'white.light'}>Ajouter l'article</Typography>
                                </Center>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} disableScrollLock>
                    <DialogTitle>
                        L'article <Typography component="span" fontWeight="bold" fontSize={20} color='green.main'>{articleData.name}</Typography> à été ajouté à votre Catalogue !
                    </DialogTitle>

                    <DialogActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'green.main', width: '45%' }}
                            onClick={() => {
                                setTimeout(() => {
                                    window.location.href = '/catalog';
                                }, 500);
                            }}
                        >
                            Voir mon catalogue
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'green.main', width: '45%' }}
                            onClick={() => {
                                setOpenDialog(false);
                                window.location.reload();
                            }}
                        >
                            Ajouter un nouvel article
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </React.Fragment >)
}
