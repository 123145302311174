import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { motion, useAnimation} from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface NameCardProps {
  photo: string;
  text: React.ReactNode;
  isPhotoRight?: boolean;
}

const NameCard: React.FC<NameCardProps> = ({ photo, text, isPhotoRight = false }) => {

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [inView, controls]);



  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.6, ease: 'easeOut' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: isPhotoRight ? 'row-reverse' : 'row',
          border: '3px solid #F6C449',
          backgroundColor: 'transparent',
          borderRadius: '20px',
          padding: '16px',
          width: '80vw',
          maxWidth: '1300px',
          margin: '20px'
        }}
      >
        <Avatar
          src={photo}
          alt="Profile Photo"
          sx={{
            width: '100px',
            height: '100px',
          }}
        />

        <Typography variant="h3" color='white.main' sx={{
          margin: "20px",
        }}>
          {text}
        </Typography>
      </Box>
    </motion.div>
  );
};

export default NameCard;
