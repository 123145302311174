import { Box, styled, TextField, Typography } from '@mui/material';
import Navbar from '../components/navbar/Navbar';
import PartnerList from './PartnerList';
import TimeLine from '../components/TimeLine';
import PresentationExtension from './PresentationExtension';
import ProjectObjective from './ProjectObjective';
import ScorePresentation from './ScorePresentation';
import GroupMemberList from './GroupMemberList';

export const Center = styled(Box)<{ component?: React.ElementType }>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const InformationPage = () => {
  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", gap: '100px' }}>
        <PresentationExtension />
        <ProjectObjective />
        <ScorePresentation />
        <PartnerList />
        <GroupMemberList />
        <TimeLine />
      </Box>
    </Box >
  );
};

export default InformationPage;