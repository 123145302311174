import { Box, Typography, useMediaQuery } from "@mui/material";

import women from '../assets/women.svg';
import { motion } from 'framer-motion';
import theme from "../theme";
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from "react";



export default function ProjectObjective() {

  const isMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [hasAppeared, setHasAppeared] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      setHasAppeared(true);
    }
  }, [inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = { duration: 0.5 };

  return (
    <motion.div
      animate={hasAppeared ? "visible" : "hidden"}
      variants={containerVariants}
      transition={transition}
      ref={ref}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "green.dark",
        borderRadius: "20px",
        padding: "30px",
        paddingBottom: isMobile ? "0px" : "30px",
        width: "80vw",
        margin: "50px",
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img src={women} alt="Women Image" style={{
            width: isMobile ? '20vw' : '40vw',
            marginRight: isMobile? "20px": "0px",
          }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: isMobile ? 'start' : 'center',
              flexDirection: 'column',
              // marginX: '4vw'
            }}
          >
            <Typography
              variant="h1"
              sx={{
                margin: "10px",
                color: 'white.main',
                textAlign: 'left',
                fontWeight: 'bold'
              }}
            >
              Le projet Localshirt
            </Typography>
            <Typography
              variant="h2"
              sx={{
                color: 'white.main',
                paddingTop: '2%',
                width: '80%',
                textAlign: 'left',
              }}
            >
              La mission de Localshirt est de sensibiliser les consommateurs sur
              <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}>
                {' '}
                l'impact de l'industrie textile
              </Box>
              , et de les encourager à faire des choix plus{' '}
              <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}>
                {' '}
                durables
              </Box>
              ,
              <Box component="span" sx={{ fontWeight: 'bold', color: 'green.main' }}>
                {' '}
                respectueux de l'environnement
              </Box>
              , et de l’être humain.
            </Typography>
          </Box>
        </Box>
      </Box >
    </motion.div>
  );
}