import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';
import { ImageInput } from '../inputs/Image';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { Card, CardContent, Divider, Button, Typography, styled, Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Loading from '../loading/Loading';
import { Center } from "../../informationPage/Information";
import BlurryBackground from '../blurryBackground/BlurryBackground';
import Navbar from '../navbar/Navbar';
import { TextInputField } from '../addArticlePage/AddArticle';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import { Email } from '@mui/icons-material';

export const ResponsiveCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '67vw',
  },
  [theme.breakpoints.down('md')]: {
    width: '90vw',
  },
  display: 'flex',
  maxHeight: '80vh',
  overflowY: 'auto',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '20px',
}));

export default function UpdateUser() {
  const [isLoading, setLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const [buttonText, setButtonText] = useState("Valider les modifications");
  const [titleText, setTitleText] = useState("Modifier le profil utilisateur");
  const navigate = useNavigate();
  const [previousUserData, setPreviousUserData] = useState({
    email: "",
    businessContact: "",
    businessName: "",
    businessDescription: "",
    business_logo: ""
  })

  let businessName = previousUserData.businessName;
  let businessContact = previousUserData.businessContact;
  let businessDescription = previousUserData.businessDescription;
  let business_logo = previousUserData.business_logo;

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const getUserInfo = async () => {
    const qr = gql(`
        query {
            profile {
                status
                userMessage
                devMessage
                user {
                    email
                    password
                    businessContact
                    businessName
                    businessDescription
                    business_logo
                    isConfirmed
                    isDeveloper
                }
            }
        }
        `);
    try {
      const { data } = await client.query({
        query: qr,
        context: {
          headers: {
            authorization: 'Bearer ' + cookies.token,
          },
        },
      });
      const UserData = data.profile.user;
      setPreviousUserData({
        email: UserData.email,
        businessName: UserData.businessName,
        businessContact: UserData.businessContact,
        businessDescription: UserData.businessDescription,
        business_logo: UserData.business_logo
      })
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };


  const updateUserInfo = async () => {
    const updateProfile = `
    query  {
      update_connected_user(
        businessContact: "${businessContact}",
        businessName: "${businessName}",
        businessDescription: "${businessDescription}",
        business_logo: "${business_logo}",
      ) {
        devMessage
        userMessage
        content {
          access_token
          email
        }
      }
    }`;
    const qr = gql(updateProfile);
    try {
      const data = await client.query({
        query: qr,
        context: {
          headers: {
            authorization: 'Bearer ' + cookies.token,
          },
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const PasswordButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      width: '72%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60vw',

    },
    marginTop: "15px",
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: theme.palette.green.main,
    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    color: theme.palette.white.main,
    borderRadius: '5px',
    maxWidth: '25vw',
  }))

  const EditButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      width: '72%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
    marginTop: "15px",
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: theme.palette.green.main,
    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    color: theme.palette.white.main,
    borderRadius: '5px',
    maxWidth: '25vw',
  }));

  useEffect(() => {
    getUserInfo();
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setButtonText("Valider");
        setTitleText("Modifier");
      } else {
        setButtonText("Valider les modifications");
        setTitleText("Modifier le profil utilisateur");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const setBusinessLogo = (value: string) => {
    business_logo = value;
  };
  const logoInput = <ImageInput prevLogo={previousUserData.business_logo} title={'Modifier le logo'} edit={true} setImageString={setBusinessLogo} imageString={business_logo} id='1' />;

  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          zIndex: 1000,
          backgroundColor: 'white',
        }}
      >
        <Navbar />
      </Box>
      <Loading open={isLoading} />
      {isLargeScreen && <BlurryBackground />}
      <Center width={'100vw'} height={'100vh'}>
        <ResponsiveCard variant="outlined" color="white">
          <CardContent sx={{ width: '100%' }}>
            <Box sx={{ marginBottom: '35px', display: 'flex' }}>
              <Box
                width='100%'
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant="h1" color="gray.main" align="left">
                  {titleText}
                </Typography>
                <EditButton onClick={updateUserInfo}>{buttonText}</EditButton>
              </Box>
            </Box>
            <Grid container justifyContent="space-between" spacing={3} width="100%">
              <Grid size={{ xs: 12, lg: 6 }}>
                <Typography variant="h2" color="gray.main" align="left">
                  Mon Entreprise
                </Typography>
                <Divider sx={{ border: '1px solid', borderColor: 'gray.main', marginBottom: '30px' }} />
                <Grid container justifyContent={{ xs: 'space-around' }}>
                  <Grid
                    size={{ lg: 6 }}
                    display="flex"
                    flexDirection="column"
                    alignItems={{ xs: 'center', lg: 'flex-start' }}
                    justifyContent={{ xs: 'center', lg: 'flex-start' }}
                    marginBottom="20px"
                  >
                    <Box className="column-element">{logoInput}</Box>
                  </Grid>
                  <Grid size={{ lg: 6 }}>
                    <Typography color="gray.main" align="left">
                      Nom de l'entreprise
                    </Typography>
                    <TextInputField
                      id="businessName"
                      placeholder={previousUserData.businessName}
                      variant="outlined"
                      onChange={(e) => businessName = e.target.value}
                    />
                    <Typography color="gray.main" align="left">
                      Description de l'entreprise
                    </Typography>
                    <TextInputField
                      multiline
                      rows={4}
                      id="businessDescription"
                      placeholder={previousUserData.businessDescription}
                      variant="outlined"
                      onChange={(e) => businessDescription = e.target.value}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ lg: 6 }}>
                <Typography variant="h2" color="gray.main" align="left">
                  Mes informations personnelles
                </Typography>
                <Divider sx={{ border: '1px solid', borderColor: 'gray.main', marginBottom: '30px' }} />
                <Grid container width={'100%'}>
                  <Grid size={{ lg: 12 }} width={'100%'}>
                    <Typography color="gray.main" align="left">
                      Adresse Email
                    </Typography>
                    <TextInputField
                      id="email"
                      placeholder={previousUserData.email}
                      variant="outlined"
                    />
                    <Typography color="gray.main" align="left">
                      Numero de telephone
                    </Typography>
                    <TextInputField
                      id="businessContact"
                      placeholder={previousUserData.businessContact}
                      variant="outlined"
                      onChange={(e) => businessContact = e.target.value}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h2" color="gray.main" align="left">
                  Mot de Passe
                </Typography>
                <Divider sx={{ border: '1px solid', borderColor: 'gray.main', marginBottom: '30px' }} />
                <Grid container width={'100%'}>
                  <Grid size={{ lg: 12 }} marginBottom={'20px'}>
                    <Typography color="gray.main" align="left">
                      Cliquez sur le bouton ci-dessous pour changer votre mot de passe, nous vous enverrons un mail avec la procédure à suivre !
                    </Typography>
                    <Center>
                      <PasswordButton onClick={() => navigate("/forgotPasswordEmail")}>{isLargeScreen ? "Changer mon mot de passe" : "Changer"}</PasswordButton>
                    </Center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </ResponsiveCard>
      </Center>
    </React.Fragment>
  );
}
