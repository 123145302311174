import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import client from '../graphqlApollo';
import { Center } from '../informationPage/Information';
import { useCookies } from 'react-cookie';
import { useCallback, useEffect, useState } from 'react';

function RegistrationConfirmation() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['token']);

  const [email, setEmail] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const { error, data } = useQuery(
    gql`
      query ConfirmUser($token: String!) {
        confirm(token: $token) {
          status
          userMessage
          devMessage
          content {
            email
            access_token
          }
        }
      }
    `,
    {
      variables: { token },
      client,
    }
  );

  useEffect(() => {
    if (!error && data?.confirm?.content?.access_token) {
      console.log("token: ", data.confirm.content.access_token);
      setCookie('token', data.confirm.content.access_token, { path: '/' });
      setEmail(data.confirm.content.email);
    }
  }, [data, setCookie]);

  const navigateInformationPage = useCallback((e: any) => {
    navigate('/mon-compte');
  }, []);

  return (
    <Center>
      <TextContainerConfBox>
        <TitleConfTypography>
          Bienvenue parmi nous,
        </TitleConfTypography>
        <UsernameTypography>
          {email}
        </UsernameTypography>
        <BodyTextConfTypography>
          Localshirt vous remercie pour l'intérêt que vous portez à notre planète,<br />
          et vous souhaite un excellent shopping!
        </BodyTextConfTypography>
        <ButtonAccount variant="contained" onClick={navigateInformationPage}>
          Commencez l'aventure!
        </ButtonAccount>
      </TextContainerConfBox>
    </Center>
  );
}

const TitleConfTypography = styled(Typography)(({ theme }) => ({
  marginTop: '10vh',
  marginBottom: '0px',
  color: theme.palette.gray.main,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: '70px',
  fontWeight: 700,
  lineHeight: 'normal',
}));

const TextContainerConfBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginTop: '20px',
  padding: '0 20px',
}));

const UsernameTypography = styled(Typography)(({ theme }) => ({
  marginTop: '10px',
  color: theme.palette.green.main,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: 'normal',
}));

const BodyTextConfTypography = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  color: theme.palette.gray2.main,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: 'normal',
}));

export const ButtonAccount = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.green.main,
  color: theme.palette.white.main,
  border: 'none',
  borderRadius: '10px',
  padding: '10px 20px',
  cursor: 'pointer',
  width: '80%',
  maxWidth: '410px',
  height: '100px',
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: '3vw',
  fontWeight: 600,
  lineHeight: 'normal',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#748E6D',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
}));

export default RegistrationConfirmation;
