import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';
import { ImageInput } from '../inputs/Image';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { Card, CardContent, Divider, Button, Typography, styled, Box } from '@mui/material';
import Grid from '@mui/material/Grid2'
import Loading from '../loading/Loading';
import { Center } from "../../informationPage/Information";
import Navbar from '../navbar/Navbar';
import { useParams } from 'react-router-dom';
import { TextInputField } from '../addArticlePage/AddArticle';

export const ResponsiveCard = styled(Card)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: '67vw',
    },
    [theme.breakpoints.down('md')]: {
        width: '90vw',
    },
    display: 'flex',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
}));
export default function UpdateArticle() {
    const [isLoading, setLoading] = useState(true);
    const [cookies] = useCookies(['token']);
    const [buttonText, setButtonText] = useState("Valider les modifications");
    const [titleText, setTitleText] = useState("Modifier l'article");
    const [PreviousArticleData, setPreviousArticleData] = useState({
        name: "",
        price: 0,
        description: "",
        url: "",
        image: "",
        environnement_desc: "",
        ethical_desc: ""
    });

    let price = String(PreviousArticleData.price)
    let description = PreviousArticleData.description
    let url = PreviousArticleData.url
    let image = PreviousArticleData.image;
    let name = PreviousArticleData.name;
    let ethical_desc = PreviousArticleData.ethical_desc;
    let environnement_desc = PreviousArticleData.environnement_desc;
    const articleId = useParams().articleId

    const getArticleInfo = async (articleId: any) => {
        const getArticleById = gql`
          query GetArticleById($articleId: String!) {
            getArticlePartnerByID(id: $articleId) {
              status
              userMessage
              devMessage
              articles {
                id
                name
                email
                redirection_url
                image
                environnement_desc
                description
                ethical_desc
                price
              }
            }
          }
        `;

        try {
            const { data } = await client.query({
                query: getArticleById,
                variables: { articleId },
                context: {
                    headers: {
                        authorization: 'Bearer ' + cookies.token,
                    },
                },
            });

            const articleData = data.getArticlePartnerByID.articles[0];
            if (articleData) {
                setPreviousArticleData({
                    name: articleData.name,
                    price: articleData.price,
                    description: articleData.description,
                    url: articleData.redirection_url,
                    image: articleData.image,
                    environnement_desc: articleData.environnement_desc,
                    ethical_desc: articleData.ethical_desc
                });
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching article:', error);
            setLoading(false);
        }
    };

    const setBusinessLogo = (value: string) => {
        image = value;
    };
    useEffect(() => {
        if (articleId) {
            getArticleInfo(articleId);
        }
    }, [articleId])

    const imageInput = <ImageInput prevLogo={PreviousArticleData.image} title={'modifier l\'image'} setImageString={setBusinessLogo} imageString={image} id='1' />;

    const savePartnerInfo = async () => {
        const updateArticlePartner = gql`
      query UpdateArticlePartner(
        $id: String!
        $name: String
        $price: String
        $image: String
        $description: String
        $environnement_desc: String
        $ethical_desc: String
      ) {
        updateArticlePartner(
          id: $id
          name: $name
          price: $price
          image: $image
          description: $description
          environnement_desc: $environnement_desc
          ethical_desc: $ethical_desc
        ) {
          status
          userMessage
          devMessage
        }
      }
    `;

        try {
            const { data } = await client.query({
                query: updateArticlePartner,
                variables: {
                    id: articleId,
                    name,
                    price,
                    image,
                    description,
                    environnement_desc: environnement_desc,
                    ethical_desc: ethical_desc
                },
                context: {
                    headers: {
                        authorization: 'Bearer ' + cookies.token,
                    },
                },
            });
            window.location.reload();
        } catch (error: any) {
            console.error('Error updating article:', error);
            if (error.graphQLErrors) {
                error.graphQLErrors.forEach((err: any) => {
                    console.error(err.message);
                });
            }
            if (error.networkError) {
                console.error('Network error:', error.networkError);
            }
        }
    };




    const EditButton = styled(Button)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            width: '72%',
        },
        [theme.breakpoints.down('md')]: {
            width: '60vw',

        },
        marginTop: "15px",
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: theme.palette.green.main,
        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
        color: theme.palette.white.main,
        borderRadius: '5px',
        maxWidth: '25vw'
    }))
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setButtonText("Valider");
                setTitleText("Modifier")
            } else {
                setButtonText("Valider les modifications");
                setTitleText("Modifier l'article");
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    zIndex: 1000,
                    backgroundColor: 'white'
                }}
            >
                <Navbar />
            </Box>
            <Loading open={isLoading} />
            <Center width={'100vw'} height={'100vh'} >
                <ResponsiveCard variant="outlined" color="white">
                    <CardContent sx={{ width: '100%' }}>
                        <Box sx={{ marginBottom: '35px', display: 'flex' }}>
                            <Box width='100%' alignItems={'center'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                <Typography variant="h1" color="gray.main" align="left">
                                    {titleText}
                                </Typography>
                                <EditButton onClick={() => savePartnerInfo()}>{buttonText}</EditButton>
                            </Box>
                        </Box>
                        <Grid container justifyContent="space-between" spacing={3} width="100%" >

                            <Grid size={{ xs: 12, lg: 6 }}>
                                <Typography variant="h2" color="gray.main" align="left">
                                    Mon Produit
                                </Typography>
                                <Divider sx={{ border: '1px solid', borderColor: 'gray.main', marginBottom: '30px' }} />
                                <Grid container justifyContent={{ xs: 'space-around' }}>
                                    <Grid
                                        size={{ lg: 6 }}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems={{ xs: 'center', lg: 'flex-start' }}
                                        justifyContent={{ xs: 'center', lg: 'flex-start' }}
                                        marginBottom="20px"
                                    >
                                        <Box className="column-element">{imageInput}</Box>
                                    </Grid>
                                    <Grid size={{ lg: 6 }}>
                                        <Typography color="gray.main" align="left">
                                            Nom de l'article
                                        </Typography>
                                        <TextInputField
                                            id="name"
                                            placeholder={PreviousArticleData.name}
                                            variant="outlined"
                                            onChange={(e) => { name = e.target.value }}
                                        />
                                        <Typography color="gray.main" align="left">
                                            Prix de l'article
                                        </Typography>
                                        <TextInputField
                                            id="price"
                                            placeholder={String(PreviousArticleData.price)}
                                            variant="outlined"
                                            onChange={(e) => { price = String(e.target.value) }}
                                        />
                                        <Typography color="gray.main" align="left">
                                            Description de l'article
                                        </Typography>
                                        <TextInputField
                                            multiline
                                            rows={3}
                                            id="description"
                                            placeholder={PreviousArticleData.description}
                                            variant="outlined"
                                            onChange={(e) => { description = e.target.value }}
                                        />
                                        <Typography color="gray.main" align="left">
                                            Url de l'article
                                        </Typography>
                                        <TextInputField
                                            id="url"
                                            variant="outlined"
                                            placeholder={PreviousArticleData.url}
                                            onChange={(e) => { url = e.target.value }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ lg: 6 }}>
                                <Typography variant="h2" color="gray.main" align="left">
                                    Description
                                </Typography>
                                <Divider sx={{ border: '1px solid', borderColor: 'gray.main', marginBottom: '30px' }} />
                                <Grid container width={'100%'}>
                                    <Grid size={{ lg: 12 }} width={'100%'}>

                                        <Typography color="gray.main" align="left">
                                            Quels sont les efforts éthiques apportés à cet article?
                                        </Typography>
                                        <TextInputField
                                            multiline
                                            rows={4}
                                            id="environnement_desc"
                                            placeholder={PreviousArticleData.environnement_desc}
                                            variant="outlined"
                                            onChange={(e) => { environnement_desc = e.target.value }}
                                        />
                                    </Grid>
                                    <Grid size={{ lg: 12 }} width={'100%'}>
                                        <Typography color="gray.main" align="left">
                                            Quels sont les efforts éthiques apportés à cet article?
                                        </Typography>
                                        <TextInputField
                                            multiline
                                            sx={{ marginBottom: '20px' }}
                                            rows={4}
                                            id="ethical_desc"
                                            placeholder={PreviousArticleData.ethical_desc}
                                            variant="outlined"
                                            onChange={(e) => { ethical_desc = e.target.value }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </ResponsiveCard >
            </Center >
        </React.Fragment >)

}
