import { Box, Typography } from "@mui/material";
import NameCard from "../components/nameCard/nameCard";

import pictureAxel from '../assets/52727831819_146b9b47af_o.jpg';
import pictureTimothee from '../assets/IMG_7461.png';
import pictureJuliette from '../assets/FB_IMG_1712578105946.jpg';
import pictureErwan from '../assets/Downgraded.png';
import pictureMartin from '../assets/20240108_111538.jpg';
import pictureQuentin from '../assets/52727831819_146b9b47af_o (copy).jpg';
import pictureSpeedy from '../assets/speedy.jpg';

export default function GroupMemberList() {
    return (
        <Box
            sx={{
                width: "100%",
                bgcolor: "green.dark",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingY: "30px",
            }}
        >
            <Typography fontWeight={'bold'} color={'white.main'} variant="h1" margin="40px">
                Les membres de l'équipe
            </Typography>
            <NameCard
                photo={pictureAxel}
                isPhotoRight={false}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        Je suis <Box sx={{ color: 'yellow.main', display: 'inline' }}>passionné</Box>,{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>créatif</Box> et{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>déterminé</Box>, toujours prêt à
                        explorer de nouvelles idées pour{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>inspirer</Box> et{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>motiver</Box> ceux qui m'entourent.
                    </Box>
                }
            />
            <NameCard
                photo={pictureQuentin}
                isPhotoRight={true}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        Hello ! Je m'appelle{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>Quentin Caniou</Box> et je fais de
                        mon mieux pour que vous puissiez trouver les{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>vêtements de vos rêves</Box> grâce
                        à <Box sx={{ color: 'yellow.main', display: 'inline' }}>l'intelligence artificielle</Box>{' '}
                        !
                    </Box>
                }
            />
            <NameCard
                photo={pictureJuliette}
                isPhotoRight={false}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        Bonjour ! Je suis{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>Juliette Destang</Box>, étudiante à
                        Epitech et <Box sx={{ color: 'yellow.main', display: 'inline' }}>passionnée</Box> par le
                        web et les paquebots.{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>Bienvenue</Box> sur localshirt !
                    </Box>
                }
            />
            <NameCard
                photo={pictureTimothee}
                isPhotoRight={true}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>Timothée De Boynes</Box>, toujours{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>curieux</Box> et en quête de
                        nouvelles technologies, je suis prêt à relever tous les défis pour{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>offrir</Box> des expériences web{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>exceptionnelles</Box>.
                    </Box>
                }
            />
            <NameCard
                photo={pictureMartin}
                isPhotoRight={false}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        Je suis un développeur backend passionné par la création de solutions robustes et
                        performantes qui alimentent les coulisses invisibles mais essentielles des
                        applications. Merci Chat GPT.
                    </Box>
                }
            />
            <NameCard
                photo={pictureErwan}
                isPhotoRight={true}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>Erwan Simonetti</Box>, côté
                        serveur, web-app ou devOps,{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>j'aime</Box> relever les défis
                        technologiques et <Box sx={{ color: 'yellow.main', display: 'inline' }}>veille</Box> à ce
                        que chaque étape soit réalisée avec{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>soin</Box> et{' '}
                        <Box sx={{ color: 'yellow.main', display: 'inline' }}>attention</Box>.
                    </Box>
                }
            />
            <NameCard
                photo={pictureSpeedy}
                isPhotoRight={false}
                text={
                    <Box sx={{ color: 'white', display: 'inline' }}>
                        Bienvenue! Je travaille actuellement sur Localshirt en tant que dévelopeur backend
                    </Box>
                }
            />
        </Box>

    );
}
