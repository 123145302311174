import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ImageInput } from '../../components/inputs/Image';
import TextInput from '../../components/inputs/Text';
import { showToast } from '../../components/tools/toast';

const LocalImageAnalyze = () => {
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [inputUrl, setInputUrl] = useState<string>('');
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (uploadedImage) {
      navigate(`/result`, { state: { base64Image: uploadedImage } });
    } else {
      showToast('error', 'Veuillez télécharger une image.');
    }
  };

  const handleTextInputRedirect = () => {
    if (inputUrl.trim()) {
      navigate('/result', { state: { url: inputUrl.trim() } });
    } else {
      showToast('error', 'Veuillez entrer une URL valide.');
    }
  };

  return (
    <Grid container
        size={{ xs: 12, md: 12 }}
        justifyContent={{ xs: 'center', md: 'center' }}
        marginBottom={'3vh'}
        sx={{ textAlign: { xs: 'center', md: 'center' }, overflow: 'auto' }}
    >
      <Grid container
          size={{ xs: 12, md: 12 }}
          justifyContent={{ xs: 'center', md: 'center' }}
          marginBottom={'3vh'}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
      >
        <Typography variant="h1"  sx={{ marginTop: { xs: "400px", md: "10px" } }}  textAlign="center" >
          Renseignez ici une image de produit textile pour découvrir nos alternatives écoresponsables
          !
        </Typography>
      </Grid>
      <Grid container
          size={{ xs: 12, md: 12 }}
          justifyContent={{ xs: 'center', md: 'center' }}
          marginBottom={'3vh'}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
      >
        <ImageInput
          setImageString={(value: string) => setUploadedImage(value)}
          imageString={uploadedImage || 'https://via.placeholder.com/200'}
          title="Téléchargez une image"
          edit={true}
          id="textile-image-upload"
        />
      </Grid>
      <Grid container
          size={{ xs: 12, md: 12 }}
          justifyContent={{ xs: 'center', md: 'center' }}
          marginBottom={'3vh'}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleRedirect}
          sx={{ marginTop: '20px' }}
        >
          Trouver des alternatives
        </Button>
      </Grid>
      <Grid container
          size={{ xs: 12, md: 12 }}
          justifyContent={{ xs: 'center', md: 'center' }}
          marginBottom={'3vh'}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
      >
        <TextInput
          title="Ou entrez une URL"
          placeholder="Ex : https://example.com/image.jpg"
          showContent={true}
          width={50}
          tag="url-input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputUrl(e.target.value)}
        />
      </Grid>
      <Grid container
          size={{ xs: 12, md: 12 }}
          justifyContent={{ xs: 'center', md: 'center' }}
          sx={{ textAlign: { xs: 'center', md: 'center' }, marginBottom: {  xs: "100px", md: "0px" } }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleTextInputRedirect}
          sx={{ marginTop: '20px' }}
          disabled={!inputUrl.trim()}
        >
          Utiliser l'URL saisie
        </Button>
      </Grid>
    </Grid>
  );
};

export default LocalImageAnalyze;
