import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        green: Palette['primary'];
    }

    interface PaletteOptions {
        green?: PaletteOptions['primary'];
    }

    interface Palette {
        gray: Palette['primary'];
    }

    interface Palette {
        yellow: Palette['primary'];
    }

    interface PaletteOptions {
        yellow?: PaletteOptions['primary'];
    }

    interface PaletteOptions {
        gray?: PaletteOptions['primary'];
    }

    interface Palette {
        gray2: Palette['primary'];
    }

    interface PaletteOptions {
        gray2?: PaletteOptions['primary'];
    }

    interface Palette {
        white: Palette['primary'];
    }

    interface PaletteOptions {
        white?: PaletteOptions['primary'];
    }
    interface Palette {
        beige: Palette['primary'];
    }

    interface PaletteOptions {
        beige?: PaletteOptions['primary'];
    }
}

let theme = createTheme({
    palette: {
        background: {
            default: "#FFF9F5",
        },
        primary: {
            main: '#789833'
        },
        secondary: {
            main: '#99AF8C'
        },
        green: {
            contrastText: '#789833',
            light: '#A2C751',
            main: '#708F31',
            dark: '#12372A',
        },
        gray: {
            main: '#414141',
            dark: '#575757',
            light: '#C7C7C7',
        },
        beige: {
            main: '#FCF9F7',
        },
        yellow: {
            main: '#F6C449'
        },
        white: {
            light: '#F6F6F6',
            main: '#FFFEFC',
            dark: '#EDEDED'
        },
        gray2: {
            light: '#436850',
            main: '#8C8C8C'
        },
    },
    typography: {
        "fontFamily": 'Poppins',
        "fontSize": 15,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#504D4D',
                },
            },
        },
    },
});

theme = createTheme(theme, {
    typography: {
        h1: {
            fontSize: 30,
            color: theme.palette.gray.dark
        },
        h2: {
            fontSize: 25,
            color: theme.palette.gray.dark
        },
        h3: {
            fontSize: 18,
            color: theme.palette.gray.dark
        },
        h4: {
            fontSize: 15,
            color: theme.palette.gray.dark
        },
        h5: {
            fontSize: 12,
            color: theme.palette.gray.dark
        },
        body2: {
            fontSize: 35,
        },
        button: {
            fontSize: 18,
            fontWeight: '500',
            textTransform: 'none'
        }
    }
})

export default theme;