import React from 'react';
import { Box, Button, useMediaQuery, Typography } from '@mui/material';
import { Searchbar } from '../../components/inputs/searchBar';
import dressIcon from './../../assets/icons/dress.svg';
import skirtIcon from './../../assets/icons/skirt.svg';
import coatIcon from './../../assets/icons/coat.svg';
import accessoriesIcon from './../../assets/icons/accessories.svg';
import allIcon from './../../assets/icons/all.svg';
import pantalonIcon from './../../assets/icons/pantalons.svg';
import tshirtIcon from './../../assets/icons/tshirt.svg';
import theme from '../../theme';
import { useCookies } from 'react-cookie';
import whiteLogo from "../../assets/white_logo.svg";
import localShirtFullLogoGreen from "../../assets/green_logo.svg";

interface LeftPanelProps {
  onSearchChange: (value: string) => void;
  onButtonClick: (category: string) => void;
}

const LeftPanel = ({ onSearchChange, onButtonClick }: LeftPanelProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [cookies] = useCookies(['token', 'theme']);

  return (
    <>
      <Box
        sx={{
          width: isMobile ? 300 : 350,
          display: 'flex',
          position: 'fixed',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: theme.palette.green.dark,
          height: '100vh',
          overflowY: 'auto',
          zIndex: 10,
        }}
      >
        {/* Logo */}
        <Box sx={{ mt: isMobile ? '10px' : '20px' }}>
          <Button
            style={{ border: 'none', background: 'none' }}
            onClick={() => window.location.replace('/')}
          >
            <img
              src={
                window.location.pathname === '/catalog'
                  ? whiteLogo
                  : localShirtFullLogoGreen
              }
              alt="Logo"
              style={{
                width: isMobile ? '150px' : '100%',
                height: 'auto',
              }}
            />
          </Button>
        </Box>

        <Box
          sx={{
            mt: isMobile ? '20px' : '50px',
            width: '100%',
            maxWidth: isMobile ? '300px' : '300px',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
          }}
        >
          <Searchbar onSearchChange={onSearchChange} />
        </Box>



        <Box
          sx={{
            mt: isMobile ? '30px' : '100px',
            width: isMobile ? '90%' : 'auto',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          {[
            { icon: allIcon, label: 'Tout voir', category: 'all' },
            { icon: tshirtIcon, label: 'Les Hauts', category: 'tshirt' },
            { icon: pantalonIcon, label: 'Les Pantalons', category: 'Pants' },
            { icon: dressIcon, label: 'Les Robes', category: 'Dress' },
            { icon: skirtIcon, label: 'Les Jupes', category: 'jupe' },
            { icon: coatIcon, label: 'Les Manteaux', category: 'Sweatshirts' },
            { icon: accessoriesIcon, label: 'Les Accessoires', category: 'Hats' },
          ].map(({ icon, label, category }) => (
            <Button
              key={category}
              sx={{
                mb: '20px',
                fontSize: isMobile ? '16px' : '20px',
                textTransform: 'none',
                color: 'white',
                justifyContent: isMobile ? 'center' : 'flex-start',
                gap: '10px',
                display: 'flex',
                width: '100%',
              }}
              startIcon={<img src={icon} alt={`${label} Icon`} />}
              onClick={() => onButtonClick(category)}
            >
              <Typography color="white.light" variant="h2">
                {label}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default LeftPanel;
